import { formatTime } from '@/filters';
import CreditiGiornalistaOverview from '@/modules/offertaFormativa/components/crediti-giornalista-overview';
import { generateKey } from '@/utils/utils';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { giornalistiCorsoService } from '../services/giornalistiCorsoService';
import { capoluoghi } from './capoluoghi';

export function stampaAttestatoPresenza(corso: giornalista.corso.item | corso.item, giornalista: giornalisti.item, ente: ente.item, iscritto: iscritto.item) {
  const fileName = `att_${generateKey(corso.id)}.pdf`;
  const doc = new jsPDF('landscape', 'mm', 'a4');

  let top = 0;

  doc.addImage("/assets/_att_background.jpg", "JPEG", 0, top, 297, 210);

  top = 15.6;
  doc.addImage("/assets/_att_logo.png", "PNG", 123.5, top, 49.3, 45.3);

  top = 73.5;
  doc.setFontSize(24);
  doc.setFont("helvetica", "bold");
  doc.text(`ATTESTATO DI FREQUENZA`, 148.5, top, { align: 'center' });

  top = 85.5;
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  doc.text(`SI ATTESTA CHE`, 148.5, top, { align: 'center' });

  top = 97.5;
  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text(giornalista.nomeCompleto.toUpperCase(), 148.5, top, { align: 'center' });

  // top += 7;
  // doc.setFontSize(12);
  // doc.setFont("helvetica", "italic");
  // doc.text(`tessera professionale n. ${giornalista.tessera}`, 148.5, top, { align: 'center' });

  const yPad = 10;
  // top += yPad;
  // doc.setFontSize(14);
  // doc.setFont("helvetica", "bold");
  // doc.text(ente.descrizione.toUpperCase(), 148.5, top, { align: 'center' });

  top += yPad;
  doc.setFont("helvetica", "normal");
  doc.text("ha frequentato l'evento dal titolo", 148.5, top, { align: 'center' });

  top += (yPad * 0.25);
  doc.setFont("helvetica", "bold");
  var splitTitle = doc.splitTextToSize(corso.titolo.toUpperCase(), 260);
  for (const title of splitTitle) {
    top += (yPad * 0.75);
    doc.text(title, 148.5, top, { align: 'center' });
  }

  top += yPad;
  doc.setFont("helvetica", "normal");
  if (iscritto && corso.continuativo && corso.virtuale && iscritto.dataConfermaCrediti) {
    doc.text(`che si è svolto il ${formatTime(iscritto.dataConfermaCrediti, 'DD MMMM YYYY')}`, 148.5, top, { align: 'center' });
  } else {
    var start = new Date(new Date(corso.data.getTime()).setHours(0, 0, 0, 0));
    var end = corso.dataScadenza ? new Date(new Date(corso.dataScadenza.getTime()).setHours(0, 0, 0, 0)) : null;
    if (!end || start.getTime() === end.getTime()) {
      doc.text(`che si è svolto il ${formatTime(corso.data, 'DD MMMM YYYY')}`, 148.5, top, { align: 'center' });
    } else {
      doc.text(`che si è svolto dal ${formatTime(start, 'DD MMMM YYYY')} al ${formatTime(end, 'DD MMMM YYYY')}`, 148.5, top, { align: 'center' });
    }
  }

  top += yPad;
  doc.text(`acquisendo ${corso.deontologico ? corso.creditiDisponibili + 2 : corso.creditiDisponibili} crediti ${corso.deontologico ? 'deontologici ' : ''}per la formazione professionale continua`, 148.5, top, { align: 'center' });

  doc.setFontSize(10);
  doc.setFont("helvetica", "italic");
  doc.text(`Roma, il ${formatTime(new Date(), 'DD/MM/YYYY')}`, 19.5, 183.7);
  doc.setFontSize(7);
  doc.setFont("helvetica", "normal");
  doc.text("Il presente certificato ha valore per gli iscritti all'Albo dei giornalisti e non può essere prodotto agli organi della pubblica amministrazione o gestori di pubblici servizi Art. 40 Co. 1 DPR 445/2000", 148.5, 197.5, { align: 'center' });

  top = 178.5;
  doc.setFont("helvetica", "italic");
  doc.setFontSize(10);
  doc.text(`Il Presidente`, 227, top);
  top += 5;
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.text(`Carlo Bartoli`, 222, top);
  top += 3;
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.text(`firma autografa sostituita a mezzo stampa ai sensi dell’art. 3 comma 2 del d.lgs. 39/93`, 188, top);
  doc.save(fileName);
}

export async function stampaAttestatoAdempienza(giornalista: giornalisti.item, ente: ente.item, periodoFormativo: periodoFormativo.item, totaleCrediti: number, totaleCreditiDeontologici: number) {

  const fileName = `Attestato_Adempimento_${periodoFormativo.dataInizio}-${periodoFormativo.dataFine}_${giornalista.cognome}_${giornalista.nome}.pdf`;
  const doc = new jsPDF('landscape', 'mm', 'a4');

  let top = 0;

  // doc.addImage("/assets/_att_background.jpg", "JPEG", 0, top, 297, 210);

  var imageProperties = doc.getImageProperties(`/assets/logoRegioni/${ente.nome}.jpg`)

  top = 10.6;
  doc.addImage(`/assets/logoRegioni/${ente.nome}.jpg`, imageProperties.fileType, 150 - imageProperties.width / 8, top, imageProperties.width / 4, imageProperties.height / 4);

  top = 53.5;
  doc.setFontSize(20);
  doc.setFont("helvetica", "bold");
  doc.text(`IL CONSIGLIO REGIONALE DELL’ORDINE DEI GIORNALISTI`, 148.5, top, { align: 'center' });

  top = 62.5;
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  doc.text(`SI ATTESTA CHE`, 148.5, top, { align: 'center' });

  top = 74.5;
  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text(giornalista.nomeCompleto.toUpperCase(), 148.5, top, { align: 'center' });

  const yPad = 10;

  top += yPad;
  doc.setFontSize(11);
  doc.setFont("helvetica", "normal");
  doc.text(`HA ASSOLTO L’OBBLIGO FORMATIVO PER IL TRIENNIO ${periodoFormativo.dataInizio}-${periodoFormativo.dataFine} `, 148.5, top, { align: 'center' });


  var query: giornalista.corso.queryParams = {
    ammesso: true,
    cancellato: false,
    frequentato: true,
    orderField: 'DataConfermaCrediti',
    orderDescending: true,
  };

  var result = await giornalistiCorsoService.ListCorsiInAnagrafica(giornalista.id, query);
  var corsiPeriodoFormativo = result.filter(x => x.periodoFormativoId == periodoFormativo.id)
  result.forEach(x => {
    if (x.deontologico) {
      x.creditiDisponibili += 2
    }
  })
  if (corsiPeriodoFormativo.length > 0) {
    top += yPad;
    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");
    doc.text(`avendo frequentato i seguenti corsi:`, 148.5, top, { align: 'center' });

    top += 5;
    const head = [['Data', 'Evento', 'Crediti Conseguiti']]
    const data = []

    corsiPeriodoFormativo.forEach(corso => {
      var array = [formatTime(corso.data, 'DD/MM/YYYY'), corso.titolo, corso.creditiDisponibili]
      data.push(array)
    });

    const breakTable = 160
    var finalY = 0
    if (data.length > 0) {
      autoTable(doc, {
        showHead: 'everyPage',
        head: head,
        body: data,
        startY: top,
        headStyles: {
          fillColor: [0, 159, 227]
        },
        didDrawCell: function (rows) {
          finalY = rows.cursor.y
          if (rows.row.index > 0 && rows.cursor.y > breakTable && rows.column.index === rows.table.columns.length - 1) {
            rows.cursor.y = 10

            footerFun(doc, 180, totaleCrediti, totaleCreditiDeontologici, ente)
            doc.addPage()
          }
        }
      });
    }
  }

  footerFun(doc, 180, totaleCrediti, totaleCreditiDeontologici, ente)
  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.text(`Totale Crediti: ${totaleCrediti} (${totaleCreditiDeontologici} deontologici )`, 15.5, (finalY === undefined || finalY === null) ? 180 : finalY + 15);
  doc.save(fileName);
}

function footerFun(doc: jsPDF, footerY, totaleCrediti, totaleCreditiDeontologici, ente) {
  var top = footerY
  doc.setTextColor('black')

  doc.setFontSize(10);
  doc.setFont("helvetica", "italic");
  doc.text(`${capoluoghi.find(x => x.ordine === ente.nome).capoluogo}, il ${formatTime(new Date(), 'DD/MM/YYYY')}`, 19.5, 190);
  doc.setFontSize(7);
  doc.setFont("helvetica", "normal");
  doc.text("Il presente attestato ha valore per gli iscritti all'Albo dei giornalisti e non può essere prodotto agli organi della pubblica amministrazione o gestori di pubblici servizi Art. 40 Co. 1 DPR 445/2000", 148.5, 200, { align: 'center' });
  doc.setFont("helvetica", "italic");
  doc.setFontSize(10);
}
