export const capoluoghi = [
  {
    "ordine": "ORG Sicilia",
    "capoluogo": "Palermo",
    "regione": "Sicilia"
  },
  {
    "ordine": "ORG Abruzzo",
    "capoluogo": "L'Aquila",
    "regione": "Abruzzo"
  },
  {
    "ordine": "ORG Basilicata",
    "capoluogo": "Potenza",
    "regione": "Basilicata"
  },
  {
    "ordine": "ORG Calabria",
    "capoluogo": "Catanzaro",
    "regione": "Calabria"
  },
  {
    "ordine": "ORG Campania",
    "capoluogo": "Napoli",
    "regione": "Campania"
  },
  {
    "ordine": "ORG Emilia Romagna",
    "capoluogo": "Bologna",
    "regione": "Emilia Romagna"
  },
  {
    "ordine": "ORG Friuli Venezia Giulia",
    "capoluogo": "Trieste",
    "regione": "Friuli Venezia Giulia"
  },
  {
    "ordine": "ORG Lazio",
    "capoluogo": "Roma",
    "regione": "Lazio"
  },
  {
    "ordine": "ORG Liguria",
    "capoluogo": "Genova",
    "regione": "Liguria"
  },
  {
    "ordine": "ORG Lombardia",
    "capoluogo": "Milano",
    "regione": "Lombardia"
  },
  {
    "ordine": "ORG Marche",
    "capoluogo": "Ancona",
    "regione": "Marche"
  },
  {
    "ordine": "ORG Molise",
    "capoluogo": "Campobasso",
    "regione": "Lombardia"
  },
  {
    "ordine": "ORG Piemonte",
    "capoluogo": "Torino",
    "regione": "Piemonte"
  },
  {
    "ordine": "ORG Puglia",
    "capoluogo": "Bari",
    "regione": "Puglia"
  },
  {
    "ordine": "ORG Sardegna",
    "capoluogo": "Cagliari",
    "regione": "Sardegna"
  },
  {
    "ordine": "ORG Toscana",
    "capoluogo": "Firenze",
    "regione": "Toscana"
  },
  {
    "ordine": "ORG Trentino Alto Adige",
    "capoluogo": "Trento",
    "regione": "Trentino"
  },
  {
    "ordine": "ORG Umbria",
    "capoluogo": "Perugia",
    "regione": "Umbria"
  },
  {
    "ordine": "ORG Valle d'Aosta",
    "capoluogo": "Aosta",
    "regione": "Valle d'Aosta"
  },
  {
    "ordine": "ORG Veneto",
    "capoluogo": "Vemezia",
    "regione": "Veneto"
  }
]
